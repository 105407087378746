import React, { useState } from "react"
import { Button, Label, Input, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp, faChevronDown, faFilter } from "@fortawesome/free-solid-svg-icons"

const Filter = ({
  pDValue,
  cpcValue,
  kindCodeValue,
  pDHandleChange,
  cpcHandleChange,
  kindCodeHandleChange,
  handleClearSearch,
}) => {
  const [ddOpen, setDdOpen] = useState(false)

  const toggleDD = () => setDdOpen((prev) => !prev)

  return (
    <Dropdown isOpen={ddOpen} toggle={toggleDD}>
      <DropdownToggle color="primary" outline className="mb-3 w-100 d-flex align-items-center justify-content-between">
        <FontAwesomeIcon icon={faFilter} size="xs" className="px-1" />
        Search criteria
        {ddOpen ? (
          <FontAwesomeIcon icon={faChevronUp} size="xs" className="px-1" />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} size="xs" className="px-1" />
        )}
      </DropdownToggle>
      <DropdownMenu className="p-2 mt-2">
        <div className="d-flex flex-column mb-3">
          <Label htmlFor="pDateInputField">Priority Date </Label>
          <Input type="date" id="pDateInputField" className="" value={pDValue} onChange={pDHandleChange} />
        </div>
        <div className="d-flex flex-column mb-3">
          <Label htmlFor="cpcInputField">CPC </Label>
          <Input
            type="text"
            id="cpcInputField"
            placeholder="CPC string"
            className=""
            value={cpcValue}
            onChange={cpcHandleChange}
          />
        </div>
        <div className="d-flex flex-column mb-3">
          <Label htmlFor="kindCodeInputField">Kind Code </Label>
          <Input
            type="text"
            id="kindCodeInputField"
            maxLength="2"
            pattern="\d*"
            placeholder="2 digit code"
            inputMode="numeric"
            value={kindCodeValue}
            onChange={kindCodeHandleChange}
          />
        </div>
        <DropdownItem divider />
        <Button
          onClick={handleClearSearch}
          outline
          color="primary"
          className="mt-3 w-100"
          disabled={!pDValue && !cpcValue && !kindCodeValue}
        >
          Clear search
        </Button>
      </DropdownMenu>
    </Dropdown>
  )
}

export default Filter
