import React, { useState } from "react"
import { Button, Dropdown, DropdownMenu, DropdownToggle, Form, FormGroup, Input } from "reactstrap"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons"

const Feedback = (messages) => {
  const [feedback, setFeedback] = useState(""),
    [isPositive, setIsPositive] = useState(),
    [feedbackDDOpen, setFeedbackDDOpen] = useState(false)

  const handleFeedback = async () => {
    await axios({
      url: "https://api.traindex.io/openai/openai-gen/feedback",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY,
      },
      data: JSON.stringify({
        intent: isPositive || false,
        "chat-history": messages,
        userFeedback: feedback,
      }),
      timeout: 2000,
    })
  }

  const toggleFeedbackDD = () => setFeedbackDDOpen((prev) => !prev)

  return (
    <Dropdown isOpen={feedbackDDOpen} toggle={toggleFeedbackDD}>
      <DropdownToggle color="primary" outline className="w-100 d-flex align-items-center justify-content-between">
        <FontAwesomeIcon icon={faThumbsUp} size="xs" className="px-1" />
        Provide feedback
        {feedbackDDOpen ? (
          <FontAwesomeIcon icon={faChevronUp} size="xs" className="px-1" />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} size="xs" className="px-1" />
        )}
      </DropdownToggle>
      <DropdownMenu className="p-2 mt-2">
        <p style={{ whiteSpace: "pre-line" }}>Please provide your valuable feedback to help us improve</p>
        <div className="d-flex align-items-center mb-2">
          <p className="m-0">Helpful?</p>
          <div
            className={`thumb ${isPositive === true ? "thumb-active" : ""} p-2`}
            color="primary"
            onClick={() => setIsPositive(true)}
          >
            <FontAwesomeIcon icon={faThumbsUp} className="px-1" />
          </div>
          <div
            className={`thumb ${isPositive === false ? "thumb-active" : ""} p-2`}
            color="primary"
            onClick={() => setIsPositive(false)}
          >
            <FontAwesomeIcon icon={faThumbsDown} className="px-1" />
          </div>
        </div>
        <Form>
          <FormGroup className="d-flex flex-column mb-0">
            <Input
              type="textarea"
              placeholder="Feedback"
              value={feedback}
              onChange={(event) => setFeedback(event.target.value)}
              className="mb-2"
            />
            <Button color="primary" onClick={() => handleFeedback()}>
              Submit
            </Button>
          </FormGroup>
        </Form>
      </DropdownMenu>
    </Dropdown>
  )
}
export default Feedback
