import React from "react"

import "./chatbot.css"
import SingleMessage from "./SingleMessage"
import { Button, Input } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"

const ChatContainer = ({
  messages,
  loadingForGen,
  promptInputValue,
  onPromptInputChange,
  handleKeyDown,
  runConversation,
}) => {
  return (
    <div className="">
      <div className="chatbot-messages w-100 rc">
        <div className="m-4">
          {messages.map((message, index) => (
            <SingleMessage key={index} message={message} />
          ))}
        </div>
      </div>
      <div className="d-flex p-2 pl-4 bg-grey-100 w-100 mb-4 mt-2 rc-100">
        {loadingForGen && (
          <div className="typing-icon-container">
            <div className="typing-dot"></div>
            <div className="typing-dot"></div>
            <div className="typing-dot"></div>
          </div>
        )}
        <Input
          type="textarea"
          value={promptInputValue}
          rows={1}
          onChange={(e) => onPromptInputChange(e)}
          placeholder="Message Dexi..."
          className="chatbot-input px-0 mr-1"
          onKeyDown={handleKeyDown}
        />
        <Button
          color="primary"
          disabled={!promptInputValue || loadingForGen}
          className="rounded-circle p-2 d-flex align-self-end"
          onClick={runConversation}
        >
          <FontAwesomeIcon icon={faPaperPlane} size="lg" className="text-white" />
        </Button>
      </div>
    </div>
  )
}

export default ChatContainer
