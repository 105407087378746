import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Header from "../Header"
import Footer from "../Footer"
import { Row, Col, Container } from "reactstrap"
import "./chatbot.css"
import prettier from "prettier/standalone"
import parserBabel from "prettier/parser-babel"
import { useLocation } from "react-router-dom"
import AWS from "aws-sdk"
import { v4 as uuidv4 } from "uuid"
import Filter from "./Filter"
import ChatContainer from "./ChatContainer"
import Feedback from "./Feedback"

const cloudWatchLogs = new AWS.CloudWatchLogs({
  region: "us-west-2",
  accessKeyId: process.env.REACT_APP_CLOUDWATCH_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_CLOUDWATCH_SECRET_ACCESS_KEY,
})
export function sendLogToCloudWatch(logGroupName, logData) {
  const logStreamName = `log-stream-${uuidv4()}`

  const createLogStream = async () => {
    try {
      await cloudWatchLogs
        .createLogStream({
          logGroupName,
          logStreamName,
        })
        .promise()
    } catch (err) {
      if (err.code !== "ResourceAlreadyExistsException") {
        throw err
      }
    }
  }
  const putLogEvents = async () => {
    const params = {
      logEvents: [
        {
          message: JSON.stringify(logData),
          timestamp: new Date().getTime(),
        },
      ],
      logGroupName,
      logStreamName,
    }

    try {
      const data = await cloudWatchLogs.putLogEvents(params).promise()
    } catch (err) {
      console.log(err)
    }
  }

  ;(async () => {
    await createLogStream()
    await putLogEvents()
  })()
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Chatbot = ({ authorize }) => {
  const [pDValue, setpDValue] = useState("")
  const [cpcValue, setcpcValue] = useState("")
  const [kindCodeValue, setkindCodeValue] = useState("")

  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY
  const [threadData, setThreadData] = useState(null)

  const pDHandleChange = (event) => {
    setpDValue(event.target.value)
  }
  const cpcHandleChange = (event) => {
    setcpcValue(event.target.value)
  }
  const kindCodeHandleChange = (event) => {
    setkindCodeValue(event.target.value)
  }

  const handleClearSearch = () => {
    setpDValue("")
    setcpcValue("")
    setkindCodeValue("")
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      runConversation()
    }
  }
  const HEADERS = {
    "OpenAI-Beta": "assistants=v2",
    "Content-Type": "application/json",
    "Authorization": "Bearer " + OPENAI_API_KEY,
  }


  const createThread = async () => {
    const url = "https://api.openai.com/v1/threads"
    var payload = {
      "messages": [{
        role:"assistant",
        content:"Hi! I am an assistant to help with Prior art search and analysis, Patent drafting and much more."
      }]
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: HEADERS,
        data:JSON.stringify(payload)
      })
    } catch (error) {
      console.error("Error creating thread:", error)
      return null
    }
    return response.data
  }

  useEffect(() => {
    const fetchThread = async () => {
      const data = await createThread()
      setThreadData(data)
    }

    fetchThread()
  }, [])



  const [loadingForGen, setLoadingForGen] = useState(false)
  const [promptInputValue, setpromptInputValue] = useState("")
  const [messages, setMessages] = useState([{
    text: "Hi! I am an assistant to help with Prior art\
  search and analysis, Patent drafting and much more.", isUser: false
  }])


  //-------------------------------------------------------------------------
  //--------------------------OPEN AI FUNCTIONs------------------------------
  //-------------------------------------------------------------------------




  const sendMessage = async (threadId, query) => {
    const url = "https://api.openai.com/v1/threads/" +threadId + "/messages"
    const payload = {
      role:"user",
      content:query
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: HEADERS,
        data: JSON.stringify(payload)
      })
    } catch (error) {
      console.error("Error Sending Message:", error)
      return null
    }
    return response.data
  }

  const getMessages = async (threadId) => {
    const url = "https://api.openai.com/v1/threads/" +threadId + "/messages"
    try {
      var response = await axios({
        url: url,
        method: "get",
        headers: HEADERS,
      })
    } catch (error) {
      console.error("Error getting Messages:", error)
      return null
    }
    return response.data
  }

  const checkCompleteStatus = async (threadId, runId) => {
    const url = "https://api.openai.com/v1/threads/" +threadId + "/runs/" + runId
    try {
      var response = await axios({
        url: url,
        method: "get",
        headers: HEADERS
      })
    } catch (error) {
      console.error("Error creating thread:", error)
      return null
    }
    return response.data
  }

  const traindex_API = async (args) => {
    const url = "https://api.traindex.io/patents/v7/priorart_search/filtered_query"
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY,
    }
    const payload = {
      request: args,
    }


    if (pDValue != ""){
      const pdValueWithoutDashs = pDValue.replace(/-/g, "")
      payload["request"]["priority_date"] = pdValueWithoutDashs
    }
    if (cpcValue != ""){
      payload["request"]["cpc"] = cpcValue
    }
    if (kindCodeValue != ""){
      payload["request"]["kind_code"] = kindCodeValue
    }


    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: headers,
        data: JSON.stringify(payload)
      })
    } catch (error) {
      console.error("Error creating thread:", error)
      return error
    }
    return response.data
  }
  const get_patent_details = async (args) => {
    const { patent_number } = args
    var response = ""
    try{
      response = await axios({
        url: "https://api.traindex.io/openai/openai-gen/getUCIDtext",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY
        },
        data: JSON.stringify({ "patent_number": patent_number })
      })
    }catch(error){
      return "Sorry aboout that. There is some internal error! Try again later"

    }
    
    return response.data
  }

  const submitToolOutput = async (threadId, runId, toolOutput, toolCallId) => {
    const jsonString = JSON.stringify(toolOutput, null, 2)

    const formattedToolOutput = prettier.format(jsonString, {
      parser: "json",
      plugins: [parserBabel],
    })
    const url = "https://api.openai.com/v1/threads/" +threadId + "/runs/" + runId + "/submit_tool_outputs"
    const payload = {
      tool_outputs:[
        {
          tool_call_id: toolCallId,
          output: formattedToolOutput

        }
      ]
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: HEADERS,
        data:JSON.stringify(payload)
      })
    } catch (error) {
      console.error("Error creating thread:", error)
      return null
    }
    return response.data
  }

  const runAssistant = async(threadId) => {
    const url = "https://api.openai.com/v1/threads/" +threadId + "/runs"
    const payload = {
      assistant_id : "asst_cTbMJgIntwwujRNjZOIxG2Lf",
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: HEADERS,
        data: JSON.stringify(payload)
      })
    } catch (error) {
      console.error("Error Sending Message:", error)
      return null
    }
    return response.data

  }
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))



  const runConversation = async (event) => {

    const cloudWatchStruct = {}

    setLoadingForGen(true)
    const prompt = promptInputValue
    cloudWatchStruct["1_user_query"]=  prompt

    const userMessageUpdate = [...messages, { text: prompt, isUser: true }]
    setMessages(userMessageUpdate)
    setpromptInputValue("")

    const threadId = threadData["id"]
    
    await sendMessage(threadId,prompt)
    const run = await runAssistant(threadId)

    var response_status = ""
    var statusCompleteRes = ""
    var patents = ""

    while (response_status != "completed"){
      await delay(3000)
      statusCompleteRes = await checkCompleteStatus(threadId,run["id"])
      if (statusCompleteRes["required_action"] != null){
        var function_props= statusCompleteRes["required_action"]["submit_tool_outputs"]["tool_calls"][0]
        var function_name = function_props["function"]["name"]
        var function_args = JSON.parse(function_props["function"]["arguments"])
        var function_tool_id = function_props["id"]

        
        if (function_name == "traindex_API"){
          cloudWatchStruct["2_openai_to_v7_req"]=  function_args

          patents = await traindex_API(function_args)
          await submitToolOutput(threadId,run["id"],patents,function_tool_id)

          cloudWatchStruct["2_openai_to_v7_res"]=  patents

        }
        if (function_name == "get_patent_details"){
          cloudWatchStruct["2_ucid_for_details_req"]=  function_args
          patents = await get_patent_details(function_args)
          await submitToolOutput(threadId,run["id"],patents,function_tool_id)
          cloudWatchStruct["2_ucid_for_details_res"]=  patents["patent_text"].substring(0, 200)
        }
      }
    
      response_status = statusCompleteRes["status"]
    }

    const thread_messages = await getMessages(threadId)
    const res = thread_messages.data[0].content[0].text.value
    

    cloudWatchStruct["3_user_res"]=  res
    const dexiMessageUpdate = [...userMessageUpdate, { text: res, isUser: false }]
    setMessages(dexiMessageUpdate)

    setLoadingForGen(false)
    sendLogToCloudWatch("/aws/traindex_ui_frontend/chatbot", cloudWatchStruct)
  }

  const handlePromptInputChange = (e) => {
    setpromptInputValue(e.target.value)
    const textarea = e?.target
    if (textarea) {
      if (textarea.value.trim() === "") {
        textarea.style.height = "auto"
      } else {
        textarea.style.height = "auto"
        textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`
      }
    }
  }


  return (
    <div className="px-1">
      {!authorize && <Header dark chatbot />}
      <div className="d-flex justify-content-center px-1 mt-5">
        <Container className="mt-4 mx-auto">
          <Row className="d-flex justify-content-between ">
            <Col className="d-flex flex-column col-2">
              <Filter
                pDValue={pDValue}
                cpcValue={cpcValue}
                kindCodeValue={kindCodeValue}
                pDHandleChange={pDHandleChange}
                cpcHandleChange={cpcHandleChange}
                kindCodeHandleChange={kindCodeHandleChange}
                handleClearSearch={handleClearSearch}
              />
              {messages && <Feedback messages={messages} />}
            </Col>
            <Col className="col-10">
              <ChatContainer
                messages={messages}
                loadingForGen={loadingForGen}
                promptInputValue={promptInputValue}
                onPromptInputChange={handlePromptInputChange}
                handleKeyDown={handleKeyDown}
                runConversation={runConversation}
              />
            </Col>

            {/* <Col className="col-1"></Col> */}
          </Row>
        </Container>
      </div>
      {!authorize && <Footer home />}
    </div>
  )
}

Chatbot.propTypes = {
  history: PropTypes.object.isRequired,
}

export default Chatbot
