import React, { useState } from "react"
import { Container, Navbar, NavbarBrand, Collapse, Nav, NavItem, NavbarToggler, NavLink, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import TopText from "../LandingPage/TopText"
import traindexLogoWhite from "../../images/trindex-final-logo-white.png"
import traindexLogo from "../../images/traindex-logo.png"
import "./header.scss"

const Header = ({ dark, chatbot }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const navLinkClassName = dark ? "landing-page-navbar-link" : "landing-page-navbar-link-light"

  return (
    <header className={`${dark && "header"}`}>
      <Container className="py-2">
        <Navbar expand="lg" className="pl-0">
          <NavbarBrand tag={Link} to="/" className="mr-0">
            <img src={dark ? traindexLogoWhite : traindexLogo} alt="Traindex logo" height="42" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} className="bg-transparent">
            <FontAwesomeIcon icon={faBars} color={dark ? "white" : "#3a003d"} />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="pl-2 pl-lg-0">
            <Nav className="ml-auto font-weight-bold" navbar>
              <NavItem className="pr-lg-3">
                <NavLink href="/chatbot" className={navLinkClassName}>
                  Chatbot
                </NavLink>
              </NavItem>
              <NavItem className="pr-lg-3">
                <NavLink href="/#why-traindex" className={navLinkClassName}>
                  Product
                </NavLink>
              </NavItem>
              <NavItem className="pr-lg-3 d-none">
                <NavLink href="#" className={navLinkClassName}>
                  Pricing
                </NavLink>
              </NavItem>
              <NavItem className="pr-lg-3">
                <a className={`nav-link ${navLinkClassName}`} href={process.env.PUBLIC_URL + "/blog"}>
                  Blog
                </a>
              </NavItem>
              <NavItem className="pr-lg-5 d-none">
                <NavLink href="#" className={navLinkClassName}>
                  About
                </NavLink>
              </NavItem>
              <NavItem className="pr-lg-3">
                <Button
                  className="login-btn rounded-pill border-secondary"
                  color={`${dark ? "primary" : "light"}`}
                  tag={Link}
                  to="/sign-in"
                >
                  Sign In
                </Button>
              </NavItem>
              <NavItem className="pt-2 pt-lg-0">
                <Button className="text-white rounded-pill" color="secondary" tag={Link} to="/sign-up">
                  Sign Up
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {dark && !chatbot && <TopText />}
      </Container>
    </header>
  )
}

export default Header
